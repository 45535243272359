import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { SNSClient, PublishCommand } from "@aws-sdk/client-sns";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (formData, e) => {
    e.target.reset();
    // console.log("Message submited: " + JSON.stringify(formData));

    const client = new SNSClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: process.env.REACT_APP_A,
        secretAccessKey: process.env.REACT_APP_B
      }
    });
    
    var params = {
      Message: JSON.stringify(formData), /* required */
      TopicArn: process.env.REACT_APP_SNS_TOPIC_ARN
    };

    const command = new PublishCommand(params);
    // async/await.
    try {
      const res = await client.send(command);
      setSubmitted(true);
      // console.log(`Message ${params.Message} sent to the topic ${params.TopicArn}`);
      // console.log("MessageID is " + res.MessageId);
    } catch (err) {
      console.error(err, err.stack);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder="Full name"
                {...register("name", { required: true })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control theme-light"
                placeholder="Email address"
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
              {errors.subject && (
                <span className="invalid-feedback">Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                rows="4"
                className="form-control theme-light"
                placeholder="Type comment"
                {...register("comment", { required: true })}
              ></textarea>
              {errors.comment && (
                <span className="invalid-feedback">Comment is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12 form-submitted">
              {submitted && (
                <span>Thank you for reaching out! We will get back to you soon.</span>
              )}
          </div>
          <div className="col-12">
            <div className="btn-bar">
              <button className="px-btn px-btn-white">Send Message</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
