import React from "react";

const ServiceContent = [
  {
    icon: "icon-mobile",
    title: "Account Health",
    descriptions: `Meet performance targets and policies required to sell on Amazon. Deliver a great experience to your customers`,
    delayAnimation: "",
  },
  {
    icon: "icon-desktop",
    title: "Listing Optimisation",
    descriptions: `Improve your product ranking on search results. Image quality, engaging title, product features and description, A+ content and keyword optimisation.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-target",
    title: "Inventory Management",
    descriptions: `Track and store products to meet customer demand quickly and efficiently.`,
    delayAnimation: "400",
  },
  {
    icon: "icon-target",
    title: "Campaign Management",
    descriptions: `Start using sponsored ads to help you meet your goals. Increase visibility and sales on Amazon with advertising.`,
    delayAnimation: "400",
  },
  {
    icon: "icon-target",
    title: "Product Launch",
    descriptions: `Get ready to launch your product on Amazon.`,
    delayAnimation: "400",
  },
  {
    icon: "icon-target",
    title: "Brand Analytics",
    descriptions: `Make informed, strategic decisions about your product portfolio and marketing/advertising activities.`,
    delayAnimation: "400",
  }
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
