import React, { useState, useEffect } from "react";
import TextLoop from "react-text-loop";

const conctInfo = {
  phone: "",
  email: "hello@sundaysales.co",
};

const sliderContent = {
  name: "Amazon Consulting",
  designation: "",
  description: `We help small businesses make the most out of the Amazon Marketplace.`,
  btnText: " Contact Us",
};

const Slider = () => {

  // const imageUrl = useWindowWidth() >= 650 ?
  //   'img/slider/charles-deluvio-tcwNN4B9uWE-unsplash.jpeg' :
  //   'img/slider/annie-spratt-hX_hf2lPpUU-unsplash.jpeg';
  
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          {/* <div className="hb-info"> */}
            {/* <a href="tel:">{conctInfo.phone}</a> */}
            {/* <a href="mailto:hello@sundaysales.co"> */}
              {/* {conctInfo.email} */}
            {/* </a> */}
          {/* </div> */}
          {/* <div className="hb-lang"> */}
            {/* <ul className="nav"> */}
              {/* <li className="active"> */}
                {/* <a href="#">EN</a> */}
              {/* </li> */}
              {/* <li>
                <a href="#">FR</a>
              </li> */}
            {/* </ul> */}
          {/* </div> */}
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          {/*<div className="row align-items-center logo">*/}
          {/*<img src={`img/slider/ss-logo.png`}></img>*/}
          {/*</div>*/}
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              {/*<div className="logo">*/}
              <img className="logo" src={"img/slider/ss-logo.png"} />
              {/*</div>*/}
              <div className="type-box">
                {/*<h6 data-aos="fade-up" data-aos-duration="1200">*/}
                {/*Hello, My name is*/}
                {/*</h6>*/}
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TextLoop>
                    <p className="loop-text lead">Account Health</p>
                    <p className="loop-text lead"> Listing Optimisation</p>
                    <p className="loop-text lead"> Inventory Management</p>
                    <p className="loop-text lead"> Campaign Management</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-white"
                    href="#contactus"
                  >
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        { useWindowWidth() >= 1200 && <div
          className="hb-me"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + 'img/slider/charles-deluvio-tcwNN4B9uWE-unsplash.jpeg'
              })`,
          }}
        ></div>}
      </section>

      {/* End Home Banner  */}
    </>
  );
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowWidth;
};

export default Slider;
